import React from 'react'
import Stack from '@mui/material/Stack'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Fab from '@mui/material/Fab'
import Typography from '@mui/material/Typography'

type ScrollTopProps = {
    anchorTagName: string
    text: string
}

const ScrollTop = ({ anchorTagName, text }: ScrollTopProps): JSX.Element => {
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        const anchor = (event.target as HTMLButtonElement).ownerDocument.querySelector(anchorTagName)

        if (anchor) {
            anchor.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            })
        }
    }
    return (
        <Stack alignItems="center" justifyContent="center" marginTop={1} marginBottom={2}>
            <Typography marginBottom={1} variant="h6">
                {text}
            </Typography>
            <Fab onClick={handleClick} size="medium">
                <KeyboardArrowUpIcon fontSize="large" />
            </Fab>
        </Stack>
    )
}

export default ScrollTop
