import type { SelectChangeEvent } from '@mui/material'
import type { Order } from '../Table/sorting'
import React from 'react'
import { useSession } from 'next-auth/react'
import { teal } from '@mui/material/colors'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import MenuItem from '@mui/material/MenuItem'
import PersonIcon from '@mui/icons-material/Person'
import CompareArrowsIcon from '@mui/icons-material/CompareArrows'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
import SportsIcon from '@mui/icons-material/Sports'
import PublicIcon from '@mui/icons-material/Public'
import CancelIcon from '@mui/icons-material/Cancel'
import { contentPermissionsQueryKey, useQueryContentPermissions } from '../../lib/hooks/useContentPermissions'
import { preventEventHandlerCalls } from '../../lib/utils/form'
import BinocularIcon from '../icon/BinocularIcon'
import { getComparator } from '../Table/sorting'
import TeamLogo from '../stats/StatsTeamLogo'
import { useGetKnicks, useGetWestchester } from '@/lib/hooks/useTeam'

type ContentPermissionsSelectProps = {
    value: DTO.UserFollowingContent[] | undefined
    onChange: (event: SelectChangeEvent<DTO.UserFollowingContent[]>) => void
    onDelete: (chipName: Enum.ContentPermissionType) => void
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const intelReportContent = new Set<Enum.IntelReportPermissionType>([
    'amateur-intel-medical-physical',
    'amateur-intel-off-court',
    'amateur-intel-on-court',
    'amateur-intel-strategy',
    'pro-intel-medical-physical',
    'pro-intel-off-court',
    'pro-intel-on-court',
    'pro-intel-strategy',
])
const scoutingReportContent = new Set<Enum.ScoutingReportPermissionType>([
    'amateur-scouting-reports',
    'pro-scouting-reports',
])
const playerDevContent = new Set<Enum.PlayerDevelopmentReportPermissionType>([
    'knicks-player-development-coaching',
    'knicks-player-development-physical',
    'westchester-player-development-coaching',
    'westchester-player-development-physical',
])
const gameReportContent = new Set<Enum.GameReportPermissionType>(['glg-game-reports'])
const workoutReportContent = new Set<Enum.ContentPermissionType>(['workout-reports'])

const communityContent = new Set<Enum.CommunityReportTeamPermissionType>(['westchester-community', 'knicks-community'])

const getChip = (
    type: Enum.ContentPermissionType,
    wesLogo: string | null,
    nykLogo: string | null
): { icon: JSX.Element; backgroundColor: string; color?: string } => {
    if (scoutingReportContent.has(type as Enum.ScoutingReportPermissionType))
        return { icon: <BinocularIcon />, backgroundColor: 'post.scouting', color: 'common.white' }
    if (intelReportContent.has(type as Enum.IntelReportPermissionType))
        return {
            icon: <PhoneInTalkIcon style={{ color: 'white' }} />,
            backgroundColor: teal[400],
            color: 'common.white',
        }
    if (playerDevContent.has(type as Enum.PlayerDevelopmentReportPermissionType))
        return {
            icon: <SportsIcon style={{ color: 'white' }} />,
            backgroundColor: 'post.playerDevelopment',
            color: 'common.white',
        }
    if (communityContent.has(type as Enum.CommunityReportTeamPermissionType))
        return {
            icon: <PublicIcon style={{ color: 'white' }} />,
            backgroundColor: 'post.community',
            color: 'common.white',
        }
    if (type === 'trade-reports') return { icon: <CompareArrowsIcon />, backgroundColor: '#81c784' }
    if (type === 'glg-game-reports')
        return {
            icon: <TeamLogo url={wesLogo} height={24} width={24} name={null} />,
            backgroundColor: 'westchester.blue',
            color: 'common.white',
        }
    if (type === 'nba-game-reports') {
        return {
            icon: <TeamLogo url={nykLogo} height={24} width={24} name={null} />,
            backgroundColor: 'knicks.blue',
            color: 'common.white',
        }
    }
    if (type === 'workout-reports')
        return {
            icon: <TeamLogo url={nykLogo} height={24} width={24} name={null} />,
            backgroundColor: 'knicks.blue',
            color: 'common.white',
        }
    return { icon: <PersonIcon />, backgroundColor: '#e57373' }
}

const ContentPermissionsSelect = ({ value, onChange, onDelete }: ContentPermissionsSelectProps): JSX.Element => {
    const { data: session } = useSession()
    const { data: content } = useQueryContentPermissions({ queryKey: contentPermissionsQueryKey })
    const { data: wes } = useGetWestchester()
    const { data: nyk } = useGetKnicks()
    const orderBy: Order = 'asc'
    const selectedTypes = new Set(value ? value.map((v) => v.slug) : [])
    const allowedPermissions =
        session?.roles && content
            ? Object.keys(session.roles.contentPermissions)
                  .reduce((acc: DTO.ContentPermission[], p) => {
                      if (
                          (intelReportContent.has(p as Enum.IntelReportPermissionType) ||
                              scoutingReportContent.has(p as Enum.ScoutingReportPermissionType) ||
                              playerDevContent.has(p as Enum.PlayerDevelopmentReportPermissionType) ||
                              communityContent.has(p as Enum.CommunityReportTeamPermissionType) ||
                              gameReportContent.has(p as Enum.GameReportPermissionType) ||
                              workoutReportContent.has(p as Enum.ContentPermissionType)) &&
                          !selectedTypes.has(p as Enum.ContentPermissionType)
                      ) {
                          const contentPermission = content.find((c) => c.slug === p)
                          if (contentPermission) acc.push(contentPermission)
                      }
                      return acc
                  }, [])
                  .slice()
                  .sort(getComparator([{ orderBy: 'displayName', order: orderBy }]))
            : []

    return (
        <FormControl sx={{ width: '100%' }} size="small">
            <InputLabel id="following-content">Content</InputLabel>
            <Select<DTO.UserFollowingContent[]>
                labelId="following-content"
                id="chip-following-content"
                multiple
                fullWidth
                size="small"
                MenuProps={MenuProps}
                value={value}
                onChange={onChange}
                input={<OutlinedInput id="select-multiple-content" label="Content" />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((s) => {
                            const { icon, backgroundColor, color } = getChip(s.slug, wes?.url || null, nyk?.url || null)
                            return (
                                <Chip
                                    key={s.slug}
                                    label={s.displayName}
                                    size="small"
                                    icon={icon}
                                    sx={{ backgroundColor, color }}
                                    onDelete={() => onDelete(s.slug)}
                                    deleteIcon={
                                        <CancelIcon
                                            sx={{ '&&&': { fontSize: '22px' } }}
                                            onMouseDown={preventEventHandlerCalls}
                                        />
                                    }
                                />
                            )
                        })}
                    </Box>
                )}
            >
                {allowedPermissions.map((contentType) => (
                    // @ts-expect-error the raw object can be used
                    <MenuItem key={contentType.slug} value={contentType}>
                        {contentType.displayName}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default ContentPermissionsSelect
