import React, { useCallback } from 'react'
import Dialog from '@mui/material/Dialog'
import useToastContext from '../../lib/hooks/useToastContext'
import { useUserFollowing, useSaveUserFollowing } from '../../lib/hooks'
import FollowingModalForm from './FollowingModalForm'

type FollowingModalProps = {
    isOpen: boolean
    handleDialogClose: () => void
}

const FollowingModal = ({ isOpen, handleDialogClose }: FollowingModalProps): JSX.Element => {
    const { data, isLoading } = useUserFollowing()
    const toastContext = useToastContext()
    const { mutateAsync } = useSaveUserFollowing({
        onSuccess: () => {
            toastContext?.addToast({
                severity: 'success',
                message: 'Following Preferences Updated',
            })
            handleDialogClose()
        },
        onError: (error) => {
            toastContext?.addToast({
                severity: 'error',
                message: error.message,
            })
        },
    })

    const submit = useCallback((values: DTO.UserFollowing) => mutateAsync(values), [mutateAsync])
    return (
        <Dialog fullWidth open={isOpen} onClose={handleDialogClose}>
            {!isLoading && (
                <FollowingModalForm
                    title="Following Preferences"
                    subTitle="Select the subjects, authors, and content to follow:"
                    initialValues={data}
                    submit={submit}
                    handleDiscard={handleDialogClose}
                />
            )}
        </Dialog>
    )
}

export default FollowingModal
