import React, { useState } from 'react'
import FormControl from '@mui/material/FormControl'
import NativeSelect from '@mui/material/NativeSelect'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import SettingsIcon from '@mui/icons-material/Settings'
import FollowingModal from './FollowingModal'

export type FilterOptionValue<IncludeFollowing extends boolean> = IncludeFollowing extends true
    ? Enum.PostFilterWithFollowing
    : Enum.PostFilter
export type FilterOptions<IncludeFollowing extends boolean> = {
    key: string
    label: string
    value: FilterOptionValue<IncludeFollowing> | ''
}

type FilterProps<T extends boolean> = {
    option: FilterOptionValue<T> | ''
    setOption: (type: FilterOptionValue<T> | '') => void
    options: FilterOptions<T>[]
}

const Filter = <T extends boolean>({ option, setOption, options }: FilterProps<T>): JSX.Element => {
    const [isDialogOpen, setIsDialogOpen] = useState(false)

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value as FilterOptionValue<T> | ''
        setOption(value)
    }

    return (
        <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }} fullWidth>
            <Box component="span" sx={{ position: 'relative', bottom: 1 }}>
                Viewing:
            </Box>
            <NativeSelect onChange={(e) => handleChange(e)} defaultValue={option}>
                {options.map((o) => (
                    <option key={o.value} value={o.value}>
                        {o.label}
                    </option>
                ))}
            </NativeSelect>
            {option === 'FOLLOWING' && (
                <>
                    <FollowingModal isOpen={isDialogOpen} handleDialogClose={() => setIsDialogOpen(false)} />
                    <IconButton edge="start" onClick={() => setIsDialogOpen(true)}>
                        <SettingsIcon fontSize="small" />
                    </IconButton>
                </>
            )}
        </FormControl>
    )
}

export default Filter
