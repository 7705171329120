import type { SetRequired } from 'type-fest'
import type { FormControlProps } from '@mui/material/FormControl'
import type { SelectProps } from '@mui/material/Select'
import React, { useCallback } from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useGetIntelTextTypes } from '../../lib/hooks'
import ErrorMessage from './ErrorMessage'

type SelectIntelTextTypeProps = Pick<FormControlProps, 'error' | 'required'> &
    SetRequired<Pick<SelectProps, 'onChange' | 'name'>, 'onChange'> & {
        value: Enum.IntelTextTypes | Enum.IntelTextTypes[] | '' | null | undefined
        textTypeName?: Enum.ReportTextType
        isItemDisabled?: (type: Enum.IntelTextTypes) => boolean
        multiple?: boolean
    }

const SelectIntelTextType = ({
    onChange,
    value,
    isItemDisabled,
    textTypeName,
    required,
    error,
    name = 'intelCategory',
    multiple = false,
}: SelectIntelTextTypeProps): JSX.Element => {
    const { data: intelTextTypes } = useGetIntelTextTypes({
        select: (textTypes) => (!textTypeName ? textTypes : textTypes.filter((t) => t.category === textTypeName)),
    })
    // fix issue where ellipses overflow is broken when some of the category names are too long in multiselect
    const renderValue = useCallback(
        (val: SelectIntelTextTypeProps['value']) =>
            Array.isArray(val)
                ? val
                      .map((v) => intelTextTypes?.find((t) => t.type === v)?.title || '')
                      .filter(Boolean)
                      .join('; ')
                      .slice(0, 100)
                : val,
        [intelTextTypes]
    )

    return (
        <FormControl fullWidth size="small" required={required} error={error}>
            <InputLabel id="category">Category</InputLabel>
            <Select
                label="Category"
                labelId="category"
                placeholder="Select Category"
                name={name}
                onChange={onChange}
                value={value}
                error={error}
                MenuProps={{ sx: { maxHeight: 355 } }}
                multiple={multiple}
                renderValue={multiple ? renderValue : undefined}
            >
                {intelTextTypes?.length === 0 && (
                    <MenuItem disabled value="">
                        No Options available
                    </MenuItem>
                )}
                {intelTextTypes?.map((i) => (
                    <MenuItem disabled={isItemDisabled?.(i.type)} key={i.type} value={i.type}>
                        {i.title}
                    </MenuItem>
                ))}
            </Select>
            {error && <ErrorMessage text="Category is required" />}
        </FormControl>
    )
}

export default SelectIntelTextType
