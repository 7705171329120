import type { SearchInputProps } from './SearchAutocomplete'
import React, { useCallback } from 'react'
import ErrorMessage from '../form/ErrorMessage'
import SearchAutocomplete from './SearchAutocomplete'

type MultipleSelectSearchProps = Pick<
    SearchInputProps<true, false>,
    'placeholder' | 'error' | 'errorText' | 'required' | 'searchType'
> & {
    value: DTO.SearchResult[] | undefined
    onChange: (value: DTO.SearchResult[]) => void
    autoFocus?: boolean
    disabledSlugs?: string[]
}

const MultipleSelectSearch = ({
    value,
    onChange: handleChange,
    placeholder,
    error,
    errorText,
    required,
    searchType,
    autoFocus,
    disabledSlugs,
}: MultipleSelectSearchProps): JSX.Element => {
    const onChange = useCallback<SearchInputProps<true, false>['onChange']>(
        (_e, options, reason) => {
            if (reason === 'blur' || reason === 'createOption') return

            const validMultiSelectOptions = options.filter((o) => typeof o !== 'string') as DTO.SearchResult[]
            handleChange(validMultiSelectOptions)
        },
        [handleChange]
    )
    return (
        <>
            <SearchAutocomplete<true>
                autoFocus={autoFocus}
                multiple
                value={value || []}
                placeholder={placeholder}
                error={error}
                errorText={errorText}
                required={required}
                onChange={onChange}
                searchType={searchType}
                fullWidth
                blurOnSelect={false}
                disabledSlugs={disabledSlugs}
            />
            {error && errorText && <ErrorMessage text={errorText} />}
        </>
    )
}

export default MultipleSelectSearch
