import type { RequireOneOrNone } from 'type-fest'
import type { SelectProps } from '@mui/material/Select'
import React, { useMemo } from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useBoardList } from '../../lib/hooks/useBoards'

type SelectBoardProps = RequireOneOrNone<
    {
        onChange: SelectProps['onChange']
        value: string | string[] | null
        league?: Enum.League | Enum.League[]
        level?: Enum.BoardLevels | Enum.BoardLevels[]
        multiple?: boolean
        name?: string
        fullWidth?: boolean
        onClick?: SelectProps['onClick']
        rankedOnly?: boolean
        entityType?: Enum.BoardEntityType
        types?: Enum.BoardTypes[]
        designation?: Enum.BoardDesignation
        disabled?: boolean
        boardIds?: readonly string[]
    },
    'league' | 'level' | 'boardIds'
>

const SelectBoard = ({
    onChange,
    value,
    league,
    level,
    multiple = false,
    name = 'boardId',
    fullWidth = undefined,
    onClick,
    rankedOnly,
    entityType = 'PLAYER',
    types,
    designation,
    disabled,
    boardIds,
}: SelectBoardProps): JSX.Element => {
    const { data: boardList } = useBoardList()
    const filteredBoardList = useMemo(() => {
        if (!boardList) return undefined

        const typeSet = new Set(types || [])
        let filteredList = boardList.filter(
            (d) =>
                d.status === 'ACTIVE' &&
                d.entityType === entityType &&
                (!rankedOnly || d.isRanked) &&
                (!typeSet.size || typeSet.has(d.type)) &&
                (!designation || d.designations?.includes(designation))
        )

        if (boardIds) {
            // If boardIds is provided, filter the boardList to only include the boards with the provided boardIds
            const boardSet = new Set(boardIds)
            filteredList = filteredList.filter((b) => boardSet.has(b.boardId))
        } else if (level || league) {
            let levelSet: Set<Enum.BoardLevels>
            if (level) {
                levelSet = new Set(Array.isArray(level) ? level : [level])
            } else {
                levelSet = new Set()
                const leagueSet = new Set(Array.isArray(league) ? league : [league])
                if (leagueSet.has('NBA') || leagueSet.has('Summer') || leagueSet.has('FIBA')) {
                    levelSet.add('Pro')
                }
                if (leagueSet.has('G-League')) {
                    levelSet.add('Pro')
                    levelSet.add('G-League')
                }
                if (
                    leagueSet.has('College') ||
                    leagueSet.has('International') ||
                    leagueSet.has('OTE') ||
                    leagueSet.has('Prep')
                ) {
                    levelSet.add('Amateur')
                }
            }
            filteredList = filteredList.filter((b) => levelSet.has(b.level))
        }
        return filteredList.sort((a, b) => a.headline.localeCompare(b.headline))
    }, [boardList, league, level, rankedOnly, entityType, types, designation, boardIds])

    return (
        <FormControl fullWidth size="small" disabled={disabled}>
            <InputLabel id="board-label">{multiple ? 'Board(s)' : 'Board'}</InputLabel>
            <Select
                labelId="board-label"
                label={multiple ? 'Board(s)' : 'Board'}
                name={name}
                onChange={onChange}
                value={value}
                multiple={multiple}
                fullWidth={fullWidth}
                onClick={onClick}
            >
                {filteredBoardList?.map((t) => (
                    <MenuItem key={t.boardId} value={t.boardId}>
                        {t.headline}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default SelectBoard
