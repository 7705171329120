import type { SelectProps } from '@mui/material/Select'
import type { CreateScoutingReport } from '../../lib/hooks/usePosts'
import React from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { usePlayerLists } from '../../lib/hooks'

type SelectPlayerListProps = {
    onChange: SelectProps['onChange']
    value: CreateScoutingReport['playerLists']
    level?: Enum.ReportLevel
    name?: string
    label?: string
    isTwoWayEligible?: boolean
    disabled?: boolean
}

const SelectPlayerList = ({
    onChange,
    value,
    level,
    name = 'playerLists',
    label = 'Lists',
    isTwoWayEligible = true,
    disabled,
}: SelectPlayerListProps): JSX.Element => {
    const { data: playerLists } = usePlayerLists({ params: { level } })
    const filteredPlayerList = isTwoWayEligible ? playerLists : playerLists?.filter((pl) => pl.name !== 'Two-Way')

    return (
        <FormControl fullWidth size="small" disabled={disabled}>
            <InputLabel id="playerLists">{label}</InputLabel>
            <Select labelId="playerLists" label={label} name={name} multiple onChange={onChange} value={value}>
                {filteredPlayerList?.length === 0 && (
                    <MenuItem disabled value="">
                        No lists available
                    </MenuItem>
                )}
                {filteredPlayerList?.map((pl) => (
                    <MenuItem key={pl.playerListId} value={pl.playerListId}>
                        {pl.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default SelectPlayerList
